// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-calendar-editor-js": () => import("./../../../src/pages/calendar/editor.js" /* webpackChunkName: "component---src-pages-calendar-editor-js" */),
  "component---src-pages-calendar-index-js": () => import("./../../../src/pages/calendar/index.js" /* webpackChunkName: "component---src-pages-calendar-index-js" */),
  "component---src-pages-community-editor-js": () => import("./../../../src/pages/community/editor.js" /* webpackChunkName: "component---src-pages-community-editor-js" */),
  "component---src-pages-community-index-js": () => import("./../../../src/pages/community/index.js" /* webpackChunkName: "component---src-pages-community-index-js" */),
  "component---src-pages-difference-index-js": () => import("./../../../src/pages/difference/index.js" /* webpackChunkName: "component---src-pages-difference-index-js" */),
  "component---src-pages-difference-volunteer-js": () => import("./../../../src/pages/difference/volunteer.js" /* webpackChunkName: "component---src-pages-difference-volunteer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-need-food-index-js": () => import("./../../../src/pages/need-food/index.js" /* webpackChunkName: "component---src-pages-need-food-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-orderslips-editor-js": () => import("./../../../src/pages/orderslips/editor.js" /* webpackChunkName: "component---src-pages-orderslips-editor-js" */),
  "component---src-pages-orderslips-index-js": () => import("./../../../src/pages/orderslips/index.js" /* webpackChunkName: "component---src-pages-orderslips-index-js" */),
  "component---src-pages-register-drive-js": () => import("./../../../src/pages/register-drive.js" /* webpackChunkName: "component---src-pages-register-drive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

